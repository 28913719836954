<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Điều kiện lọc</div>
                </div>
                <div class="dialog-content">
                    <div class="page-list-body">
                        <div class="form-body" style="padding-top: 20px;">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="search.fromDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.search.fromDate.$error }">
                                        <label>Từ ngày</label>
                                        <span class="md-error" v-if="submitted && !$v.search.fromDate.required">Vui lòng chọn từ ngày</span>
                                    </md-datepicker>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-datepicker v-model="search.toDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.search.toDate.$error }">
                                        <label>Tới ngày</label>
                                        <span class="md-error" v-if="submitted && !$v.search.toDate.required">Vui lòng chọn tới ngày</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="orderCode" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                            <label>Đơn hàng</label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                        </md-autocomplete>
                                        <md-button @click="openOrder()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </md-content>
            <md-dialog-actions>
                <md-button class="md-primary" @click="submit()">Search</md-button>
                <md-button class="md-primary" @click="showDialog = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
        <orderList ref="orderList" @close="closeOrder" />
    </div>
</template>

<script>
    import orderService from '../../../api/orderService';
    import messageBox from '../../../utils/messageBox'; 
    import common from '../../../mixins';
    import { required } from 'vuelidate/lib/validators';
    import orderList from '../../../components/popup/_OrderList.vue';

    export default {
        components: {
            orderList
        },
        data: () => ({
            showDialog: false,
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, fromDate: common.dateNow, toDate: common.dateNow, orderId: 0 },
            submitted: false,
            orderCode: '',
            orders: [],
        }),

        created() {
        },

        methods: { 
            //Product
            closeOrder(item){
                this.orderCode = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.orderCode = val.orderCode;
                this.search.orderId = val.id;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, typeCode: '', keyword: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.$emit('close', this.search);
                this.showDialog = false;
            },

            open(){
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },
        },
        watch: { 
            orderCode: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            }
        },
        validations: {
            search: {
                fromDate: { required },
                toDate: { required },
            }
        }
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 700px;
        height: 200px;
        max-width: 700px;
        max-height: 200px;
    }
    .dialog-content {
        height: 200px;
    }
</style>